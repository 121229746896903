<template>
  <div class="vh-100 dragon-tiger-view">
    <div class="d-flex justify-content-between align-items-center w-75 mx-auto">
      <h2>Tiger Dragon</h2>
      <p class="text-light">
        <router-link to="/" class="text-light">Volver al dashboard</router-link>
      </p>
    </div>
    <div class="game-container">
      <div>
        <p>Video / Transmision</p>
        <div class="cards-container">
          <div class="round-result progress-bar" v-if="roundOpened">
            <span class="round-msg">Ronda abierta</span>
            <span class="inner-bar" ref="innerBar"></span>
          </div>
          <div class="round-result" v-if="roundClosed">
            Resultado: {{ winMsg }}
          </div>
          <div class="cards-results">
            <p>Dragon</p>
            <img v-if="roundOpened" :src="redBack" :alt="redBack" />
            <img v-if="roundClosed" :src="dragonCard.url" :alt="dragonCard" />
          </div>
          <div class="cards-results">
            <p>Tiger</p>
            <img v-if="roundOpened" :src="greyBack" :alt="greyBack" />
            <img v-if="roundClosed" :src="tigerCard.url" :alt="tigerCard" />
          </div>
        </div>
      </div>
      <div class="game-bet-table">
        <div class="bet-table-analyst-container">
          <div class="bet-table-analyst p-2">
            <p>Jackpot</p>
            <p>Jackpot active: {{ activeJackpot.status }}</p>
            <p v-if="activeJackpot.status">
              Jackpot type: {{ activeJackpot.type }}
            </p>
            <p>Jackpot rounds: {{ countJackpotActive }} / {{ limitJackpot }}</p>
          </div>
          <div class="bet-table-analyst-balance mt-2">
            Saldo: {{ `${user.balance} ${user.currency}` }}
          </div>
        </div>
        <div class="bets-table-container">
          <div class="bets-table">
            <div
              class="bet-option even-red red-options"
              @click="(e) => handleSecondaryBet('dragon', 'even', e)"
            >
              Par
            </div>
            <div
              class="bet-option odd-red red-options"
              @click="(e) => handleSecondaryBet('dragon', 'odd', e)"
            >
              Impar
            </div>
            <div
              class="bet-option small-red red-options"
              @click="(e) => handleSecondaryBet('dragon', 'small', e)"
            >
              Pequeña
            </div>
            <div
              class="bet-option big-red red-options"
              @click="(e) => handleSecondaryBet('dragon', 'big', e)"
            >
              Grande
            </div>
            <div
              class="bet-option table-dragon red-options"
              @click="(e) => handleBet('dragon', e)"
            >
              Dragón
            </div>
            <div
              class="bet-option table-draw"
              @click="(e) => handleBet('tie', e)"
            >
              Empate
            </div>
            <div
              class="bet-option table-drawPerfect"
              @click="(e) => handleBet('perfectTie', e)"
            >
              Empate perfecto
            </div>
            <div
              class="bet-option table-tiger yellow-options"
              @click="(e) => handleBet('tiger', e)"
            >
              Tigre
            </div>
            <div
              class="bet-option even-yellow yellow-options"
              @click="(e) => handleSecondaryBet('tiger', 'even', e)"
            >
              Par
            </div>
            <div
              class="bet-option odd-yellow yellow-options"
              @click="(e) => handleSecondaryBet('tiger', 'odd', e)"
            >
              Impar
            </div>
            <div
              class="bet-option small-yellow yellow-options"
              @click="(e) => handleSecondaryBet('tiger', 'small', e)"
            >
              Pequeña
            </div>
            <div
              class="bet-option big-yellow yellow-options"
              @click="(e) => handleSecondaryBet('tiger', 'big', e)"
            >
              Grande
            </div>
          </div>
          <div class="bets-table-chips">
            <vs-tooltip class="d-flex justify-content-center">
              <vs-button
                warn
                class="reset-btn btn-container border-0"
                @click="handleReset"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                  class="btn-icon"
                >
                  <path
                    fill="currentColor"
                    d="M12 16c1.671 0 3-1.331 3-3s-1.329-3-3-3s-3 1.331-3 3s1.329 3 3 3z"
                  />
                  <path
                    fill="currentColor"
                    d="M20.817 11.186a8.94 8.94 0 0 0-1.355-3.219a9.053 9.053 0 0 0-2.43-2.43a8.95 8.95 0 0 0-3.219-1.355a9.028 9.028 0 0 0-1.838-.18V2L8 5l3.975 3V6.002c.484-.002.968.044 1.435.14a6.961 6.961 0 0 1 2.502 1.053a7.005 7.005 0 0 1 1.892 1.892A6.967 6.967 0 0 1 19 13a7.032 7.032 0 0 1-.55 2.725a7.11 7.11 0 0 1-.644 1.188a7.2 7.2 0 0 1-.858 1.039a7.028 7.028 0 0 1-3.536 1.907a7.13 7.13 0 0 1-2.822 0a6.961 6.961 0 0 1-2.503-1.054a7.002 7.002 0 0 1-1.89-1.89A6.996 6.996 0 0 1 5 13H3a9.02 9.02 0 0 0 1.539 5.034a9.096 9.096 0 0 0 2.428 2.428A8.95 8.95 0 0 0 12 22a9.09 9.09 0 0 0 1.814-.183a9.014 9.014 0 0 0 3.218-1.355a8.886 8.886 0 0 0 1.331-1.099a9.228 9.228 0 0 0 1.1-1.332A8.952 8.952 0 0 0 21 13a9.09 9.09 0 0 0-.183-1.814z"
                  />
                </svg>
              </vs-button>
              <template #tooltip>Reset</template>
            </vs-tooltip>
            <!-- <vs-tooltip class="d-flex justify-content-center">
              <vs-button warn class="bet-btn btn-container border-0" @click="makeBet">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" class="btn-icon">
                  <path fill="white"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zm-2.5-3.5l7-4.5l-7-4.5v9z" />
                </svg>
              </vs-button>
              <template #tooltip>Apostar!</template>
            </vs-tooltip> -->

            <div class="chips-container">
              <Chips
                :amount="chipSelected"
                :casinoChips="casinoChips"
                @changeChip="changeChip"
                @updateBalanceBet="updateBalanceBet"
              />
            </div>
            <vs-tooltip class="mt-4 ms-4" success>
              <vs-checkbox ref="jackpotBtn" v-model="jackpot" success>
              </vs-checkbox>
              <template #tooltip>Click para jugar con Jackpot!</template>
            </vs-tooltip>
          </div>
        </div>
        <div class="history-container">
          <div class="history">
            <h2>Historial</h2>
            <div v-for="(round, i) in rounds" :key="i">Ronda: {{ round }}</div>
          </div>
          <div class="history-container-totalbet mt-2">
            Apuesta total: {{ totalBetting }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client';
import Chips from './components/chips.vue';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { Chips },
  data() {
    return {
      runningInterval: false,
      //
      user: {
        balance: 10000,
        currency: '$',
      },
      totalBet: 0,
      totalBetting: 0,
      chipSelected: 0,
      chip: {},
      casinoChips: [
        {
          number: 1,
          value: 1,
          color: {
            primary: '#383950',
            secondary: '#458b97',
          },
        },
        {
          number: 10,
          value: 10,
          color: {
            primary: '#383950',
            secondary: '#458b97',
          },
        },
        {
          number: 50,
          value: 50,
          color: {
            primary: '#383950',
            secondary: '#458b97',
          },
        },
        {
          number: 100,
          value: 100,
          color: {
            primary: '#383950',
            secondary: '#458b97',
          },
        },
        {
          number: 200,
          value: 200,
          color: {
            primary: '#383950',
            secondary: '#458b97',
          },
        },
        {
          number: 500,
          value: 500,
          color: {
            primary: '#383950',
            secondary: '#458b97',
          },
        },
      ],
      interval: null,
      timeToInterval: 1000,
      timer: 0,
      cards: [],
      greyBack: '',
      redBack: 'red_back.png',
      roundOpened: false,
      roundClosed: false,
      tigerCard: null,
      dragonCard: null,
      winMsg: '',
      resultCard: {},
      betType: null,
      countJackpotActive: 0,
      activeJackpot: { status: false, type: '' },
      userIsBetting: false,
      limitJackpot: 9,
      jackpot: false,

      // SOCKET
      SOCKET_URL: process.env.VUE_APP_DRAGON_TIGER_API,
      socket: null,
      gameId: null,
      gameUuid: null,
      GAME_ID: null,
      OPERATOR_ID: '705ba941-39ff-4369-b7d3-7750583dfcb8',
      PLAYER_ID: '01eae1f3-2131-4d4e-b7d8-cc6090e77629',
      CURRENCY: 'USD',
      USER_ID: '335950',
      identifierNumber: null,
      // time,
      totalTime: 0,

      // round
      rounds: [],
      currentRoundId: '',
      // secondary-bets
      secondaryBets: [],
      secondaryBetsKeys: ['even', 'odd', 'small', 'big'],
      secondaryBet: {},
    };
  },
  computed: {
    ...mapGetters({
      success: 'dragonTiger/isSuccess',
      isWinner: 'dragonTiger/isWinner',
      getEarnings: 'dragonTiger/getEarnings',
    }),
  },
  methods: {
    async makeBet() {
      if (!this.totalBet)
        return Swal.fire('No se ha seleccionado una chip', '', 'info');

      const basic = { dragon: 0, tiger: 0, tie: 0, perfectTie: 0 };
      const toBet = {
        ...basic,
        ...this.secondaryBet,
        [this.betType]: this.totalBet,
      };

      const bet = {
        player: this.PLAYER_ID,
        currency: this.CURRENCY,
        round: this.currentRoundId,
        // dragonTiger: this.GAME_ID,
        gameId: this.GAME_ID,
        totalAmount: this.totalBetting,
        bet: toBet,
        operatorId: this.OPERATOR_ID,
        user_id: this.USER_ID,
        platform: 'desktop',
      };

      if (this.jackpot) {
        const jackpot = {
          jackpot: {
            amount: this.totalBet,
            winner: this.betType,
          },
        };
        Object.assign(bet.bet, jackpot);
        this.activeJackpot = { status: true, type: this.betType };
      }

      try {
        this.createBetSocket(bet);
        // await this.createBet({ bet });
        // if (!this.success) return Swal.fire("Error haciendo la apuesta", "", "info")

        // this.openWinNotification("top-right", "success", "Apuesta hecha");
        // this.user.balance -= this.totalBetting
      } catch (error) {
        console.log('ERROR MAKING BET', error);
        Swal.fire('Error haciendo la apuesta', '', 'info');
      }
    },
    removeClassOfMany(selector) {
      const actives = document.querySelectorAll(`.${selector}`);
      actives.forEach((el) => el.classList.remove(selector));
    },
    handleBet(type, e) {
      const { target } = e;
      // this.removeClassOfMany("bet-option-jackpot")
      target.classList.toggle('bet-option-jackpot');
      this.betType = type;
      console.log('AQUI APUESTA', this.betType);
      this.totalBetting += this.totalBet;
      this.userIsBetting = true;
    },
    handleSecondaryBet(winner, type, e) {
      if (!this.totalBet)
        return this.openWinNotification(
          'top-right',
          'danger',
          'Seleccione una ficha',
          '',
          1000
        );
      const { target } = e;

      const contrapart = this.secondaryBets.find((bet) => bet[type]);

      if (contrapart) {
        contrapart.target.classList.toggle('bet-option-jackpot');
      }

      target.classList.toggle('bet-option-jackpot');

      this.secondaryBets.push({
        [type]: { winner, amount: this.totalBet },
        target,
        bet: type,
      });
      const newBets = this.secondaryBets.filter((bet) => {
        if (bet.target.classList.contains('bet-option-jackpot')) {
          return bet;
        }
      });
      this.secondaryBets = newBets;

      let removeDuplicated = this.secondaryBets.map((item) => {
        return [item.bet, item];
      });
      let betsMapArr = new Map(removeDuplicated);
      let uniques = [...betsMapArr.values()];

      this.secondaryBets = uniques;

      this.secondaryBetsKeys.forEach((key) => {
        this.secondaryBets.forEach((bet) => {
          if (bet[key]) {
            Object.assign(this.secondaryBet, {
              [key]: {
                ...bet[key],
              },
            });
          }
        });
      });

      this.updateAmount(this.secondaryBet);
    },
    updateAmount(newBet) {
      console.log('newBet', newBet);
      let counter = 0;
      for (let bet in newBet) {
        counter += newBet[bet].amount;
      }

      this.totalBetting = counter;
    },
    changeChip(chip) {
      this.chipSelected = chip;
    },
    updateBalanceBet(chip) {
      this.totalBet = chip;
    },
    handleReset() {
      this.totalBet = 0;
      this.totalBetting = 0;
      this.betType = '';
      this.userIsBetting = false;
      this.chipSelected = 0;
      this.secondaryBet = {};
      this.secondaryBets = [];

      this.removeClassOfMany('bet-option-jackpot');
      this.handleBetsOptions('.bet-option', 'all');
    },
    generateInterval(timeToBet) {
      this.roundClosed = false;
      this.roundOpened = true;
      this.timer = timeToBet;

      if (!this.timer && this.runningInterval) {
        this.cleanBetInterval();
      }
      this.interval = setInterval(() => {
        if (!this.timer) {
          if (this.activeJackpot.status) {
            this.countJackpotActive++;
          }
          // this.getRandomResult()

          if (this.userIsBetting) {
            this.makeBet();
          }

          return this.cleanBetInterval();
        }

        this.runningInterval = true;
        this.timer--;
        this.progressBar(this.timer, this.totalTime);
      }, this.timeToInterval);
    },
    cleanBetInterval() {
      clearInterval(this.interval);
      // this.countRoundJackpotActive();
    },
    generateCards() {
      let type = '';
      for (let pike = 1; pike <= 4; pike++) {
        switch (pike) {
          case 1:
            type = 'pica';
            break;
          case 2:
            type = 'corazon';
            break;
          case 3:
            type = 'diamante';
            break;
          case 4:
            type = 'trebol';
            break;
          default:
            break;
        }
        for (let card = 1; card <= 13; card++) {
          let number =
            card === 1
              ? 'A'
              : card === 11
                ? 'J'
                : card === 12
                  ? 'Q'
                  : card === 13
                    ? 'K'
                    : card;
          const name = `${number}${type}.png`;
          this.cards.push({ url: name, type, value: card });
        }
      }
    },
    async getBackCards() {
      const greyBack = await import('../../assets/images/cartas/grey_back.png');
      const redBack = await import('../../assets/images/cartas/red_back.png');
      this.greyBack = greyBack.default;
      this.redBack = redBack.default;
    },
    async getRandomResult(result, winner) {
      const { card1: dragon, card2: tiger } = result;

      this.tigerCard = {
        url: `${tiger.name}${tiger.type}.png`,
        value: tiger.value,
        type: tiger.type,
        name: 'tiger',
      };
      this.dragonCard = {
        url: `${dragon.name}${dragon.type}.png`,
        value: dragon.value,
        type: dragon.type,
        name: 'dragon',
      };

      const tigerCard = await import(
        `../../assets/images/cartas/${this.tigerCard.url}`
      );
      const dragonCard = await import(
        `../../assets/images/cartas/${this.dragonCard.url}`
      );
      this.tigerCard = { url: tigerCard.default };
      this.dragonCard = { url: dragonCard.default };

      this.roundOpened = false;
      this.roundClosed = true;

      this.getResultCard(this.dragonCard, this.tigerCard, winner);
    },
    getResultCard(dragonCard, tigerCard, winner) {
      if (winner === 'perfectTie') {
        this.resultCard = { ...tigerCard, winner: 'perfectTie' };
        this.winMsg = 'Empate perfecto';
      }
      if (winner === 'tie') {
        this.resultCard = { ...tigerCard, winner: 'tie' };
        this.winMsg = 'Empate';
      }
      if (winner === 'dragon') {
        this.resultCard = { ...tigerCard, winner: 'dragon' };
        this.winMsg = 'gana dragon';
      }
      if (winner === 'tiger') {
        this.resultCard = { ...tigerCard, winner: 'tiger' };
        this.winMsg = 'gana tigre';
      }

      this.getWinner();
    },
    async getWinner() {
      if (!this.userIsBetting) return false;

      try {
        const params = this.getParams();
        this.socket.emit('winner', params);
        // const options = { params };
        // await this.fetchWinner({ options });
      } catch (error) {
        console.log('ERROR GET WINNER', error);
      }

      if (this.activeJackpot) return;

      this.countJackpotActive = 0;
      this.userIsBetting = false;
      this.handleReset();
    },
    getParams() {
      const isActive = this.isBoilerPlateActive();

      let params = {};
      if (isActive) {
        params = {
          player: this.PLAYER_ID,
          gameId: this.gameUuid,
          dragonTigerId: this.gameUuid,
          uuid: this.rounds[0],
          operator: this.OPERATOR_ID,
        };
      } else {
        params = {
          playerId: this.PLAYER_ID,
          dragonTigerId: this.GAME_ID,
          identifierNumber: this.identifierNumber,
        };
      }

      return params;
    },
    makeJackpot() {
      const possibleJackpot = ['dragon', 'tiger', 'tie', 'perfectTie'];
      this.handleBetsOptions('.bet-option', 'none');

      if (!possibleJackpot.includes(this.betType)) return;

      this.activeJackpot = { status: true, type: this.betType };
      // this.$refs.jackpotBtn.$el.disabled = true
    },
    handleBetsOptions(selector, value) {
      const betsOptions = document.querySelectorAll(selector);
      if (!betsOptions.length) return;
      betsOptions.forEach((bet) => {
        bet.style.pointerEvents = value;
      });
    },
    countRoundJackpotActive() {
      if (this.countJackpotActive === this.limitJackpot) {
        alert('Limpiar jackpot');
        this.countJackpotActive = 0;
        this.activeJackpot = { status: false, type: '' };
      }
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: 'auto',
      });
    },
    progressBar(position, total) {
      const percent = (position * 100) / total;

      this.$refs.innerBar.style.width = `${percent}%`;

      if (percent < 20) {
        this.$refs.innerBar.style.background = '#c22b2b';
      }
    },
    createSocket() {
      this.socket = io(this.SOCKET_URL, {
        transports: ['websocket'],
        query: {
          rouletteId: this.gameId,
          gameUuid: this.gameUuid,
          userId: this.PLAYER_ID,
        },
      });

      this.listenEvents();
    },
    listenEvents() {
      this.socket.on(`round:start`, (data) => {
        this.openRound(data);
      });

      this.socket.on('round:end', (data) => {
        const { winner, result } = data;
        this.getRandomResult(result, winner);
      });

      this.socket.on('jackpot:winner', ({ earning }) => {
        const msg = '¡FELICIDADES! Ganaste con un jackpot';
        const amount = `${earning} USD`;
        this.openWinNotification('top-left', 'success', msg, amount, 'none');
        this.user.balance += earning;
        this.countJackpotActive = 0;
      });

      this.socket.on('bet:err', (data) => {
        const { msg } = data;

        if (!msg)
          return this.openWinNotification(
            'top-right',
            'error',
            `Error haciendo apuesta`
          );

        this.openWinNotification(
          'top-right',
          'error',
          `Error haciendo apuesta: ${msg}`
        );
      });

      this.socket.on('bet:success', (data) => {
        const { ok } = data;
        if (!ok)
          return this.openWinNotification(
            'top-right',
            'error',
            'Error haciendo apuesta'
          );

        this.openWinNotification('top-right', 'success', 'Apuesta hecha');
        this.user.balance -= this.totalBetting;
      });

      this.socket.on('winner', (data) => {
        const { winner, totalEarnings } = data;
        if (!winner) {
          this.handleReset();
          return this.openWinNotification(
            'top-right',
            'danger',
            'No ganaste',
            'No ganaste'
          );
        }

        this.openWinNotification(
          'top-right',
          'success',
          'Felicidades',
          'Ganaste: ' + totalEarnings + ' USD'
        );
        this.user.balance += totalEarnings;
        this.handleReset();
      });
    },
    createBetSocket(bet) {
      this.socket.emit('bet', bet);
    },
    openRound(data) {
      const { round } = data;
      const { start_date, end_date, ID_Ronda, identifierNumber } = round;
      this.identifierNumber = identifierNumber;
      this.currentRoundId = ID_Ronda;
      this.rounds.unshift(ID_Ronda);
      const initialTime = new Date(start_date);
      const endTime = new Date(end_date);
      this.totalTime = (endTime.getTime() - initialTime.getTime()) / 1000;

      const timeToBet = this.totalTime - 2;

      this.generateInterval(timeToBet);
    },
    async getData() {
      this.gameId = this.$route.params.id;
      this.gameUuid = this.$route.params.uuid;
      // await this.getPlayer()
      this.createSocket();
    },
    async getPlayer() {
      try {
        const { data } = await this.$http.get('/player-round/find-by-term', {
          params: {
            term: 'demousd',
          },
        });
        if (!data.ok) return Swal.fire('Player not found', '', 'info');
        const { players } = data;
        const playerUsd = players.find(
          (player) => player.username === 'demousd'
        );
        this.PLAYER_ID = playerUsd._id;
      } catch (error) {
        console.log('ERROR GET OPERATOR', error);
      }
    },
    isBoilerPlateActive() {
      const isActive =
        process.env.VUE_APP_DRAGON_TIGER_ACTVE === 'false' ? false : true;
      return isActive;
    },
    ...mapActions({
      createBet: 'dragonTiger/createBet',
      fetchWinner: 'dragonTiger/getWinner',
    }),
  },
  mounted() {
    const isActive = this.isBoilerPlateActive();
    this.SOCKET_URL = isActive
      ? process.env.VUE_APP_DRAGON_TIGER_API
      : process.env.VUE_APP_API;
    this.GAME_ID = isActive ? this.$route.params.uuid : this.$route.params.id;
    this.CURRENCY = isActive ? 'USD' : '6377d0e9bc2612755f97534b';
    this.generateCards();
    this.getBackCards();
    this.getData();
  },
  beforeDestroy() {
    this.socket.disconnect();
    return this.cleanBetInterval();
  },
};
</script>

<style scoped>
.dragon-tiger-view {
  background: #cc1717;
  background: -webkit-radial-gradient(center, #cc1717, #5d1c1c);
  background: -moz-radial-gradient(center, #cc1717, #5d1c1c);
  background: radial-gradient(ellipse at center, #cc1717, #5d1c1c);
  color: #fff;
}

.game-container {
  display: grid;
  /* template */
  grid-template-rows: repeat(2, 50%);
  /*  */
  height: 90%;
  width: 98vw;
  margin: 0 auto;
}

.game-bet-table {
  display: grid;
  /* Template */
  grid-template-columns: 25% 50% 25%;
  /*  */
  max-height: 400px;
  overflow-y: hidden;
}

/* START ANALYST */
.bet-table-analyst-container,
.history-container {
  /*  */
  display: grid;
  grid-template-rows: 80% 20%;
  /*  */
  border-radius: 6px;
  width: 98%;
  padding: 10px;
  margin: 0 auto;
  max-height: 300px;
}

.bet-table-analyst,
.history {
  background: rgba(100, 100, 100, 20%);
  overflow-y: scroll;
}

/* END ANALYST */

/* BETS */
.bets-table-container {
  display: grid;
  /* Template */
  grid-template-rows: 70% 30%;
  /*  */
}

.bets-table {
  display: grid;
  /* Template */
  grid-template-rows: repeat(3, 33%);
  grid-template-columns: repeat(7, 14.2%);
  /*  */

  color: #fff;
  /*  */
  font-family: Arial;
  font-size: 12px;
  width: 95%;
  margin: 0 auto;
  /* vars */
  --padding-bet: 5px;
  gap: 2px;
  --red-background: linear-gradient(90deg, #ff0210, #ff5100);
  --red-border: 2px solid #ff5e00;
}

.red-options {
  background: var(--red-background);
  border: var(--red-border);
  padding: var(--padding-bet);
}

.yellow-options {
  background: linear-gradient(90deg, #a5a010, #e9db30);
  border: 2px solid #e9db30;
  padding: var(--padding-bet);
}

.table-dragon {
  /*  */
  grid-row: 2/4;
  grid-column: 2/4;
  /*  */
  width: 98%;
  height: 98%;
  align-self: center;
  justify-self: center;
}

.table-draw {
  background: linear-gradient(91deg, #02a524, #51b554);
  border: 2px solid #51b554;

  /*  */
  grid-row: 2/3;
  grid-column: 4/5;
  /*  */
  padding: var(--padding-bet);
  width: 98%;
  height: 98%;
  align-self: center;
  justify-self: center;
}

.table-drawPerfect {
  background: linear-gradient(91deg, #0aa524, #51b554);
  border: 2px solid #51b554;

  /*  */
  grid-row: 3/4;
  grid-column: 4/5;
  /*  */
  padding: var(--padding-bet);
  width: 98%;
  height: 98%;
  align-self: center;
  justify-self: center;
}

.table-tiger {
  /*  */
  grid-row: 2/4;
  grid-column: 5/7;

  /*  */
  width: 98%;
  height: 98%;
  align-self: center;
  justify-self: center;
}

/* END BET */

/* START CHIPS */
.bets-table-chips {
  --gradient-btn: rgba(100, 100, 100, 20%);
  display: grid;
  /* Template */
  grid-template-columns: repeat(2, 10%) 60% repeat(2, 10%);
}

.bets-table-chips button {
  background: var(--gradient-btn);
  width: 70%;
  height: 70%;
  align-self: center;
  justify-self: center;
}

/* btn icon */

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  width: 30px;
  height: 30px;
}

.btn-icon path {
  fill: white;
}

/* Bet option */
.bet-option {
  cursor: pointer;
}

.bet-option:hover {
  box-shadow: 0 0 2px 1px white;
}

.bet-option-jackpot {
  box-shadow: 0 0 10px 0.5px rgb(90, 231, 252);
}

.chips-container {
  grid-column: 3/4;
}

.chips-container > * {
  height: 100%;
}

/* CARDS */

.cards-container {
  height: 100%;
  display: grid;
  grid-template-rows: 20% 80%;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  justify-content: center;
}

.round-result {
  background: rgba(100, 100, 100, 20%);

  grid-row: 1/2;
  grid-column: 1/3;
  margin: 0 auto;
  padding: 10px;
  width: 80%;
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
}

.cards-results {
  margin: 0 auto;
}

.cards-container img {
  width: 60px;
  height: auto;
}

.progress-bar {
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
}

.round-msg {
  position: absolute;
  z-index: 999;
  width: 100%;
}

.inner-bar {
  position: absolute;
  height: 100%;
  width: 0%;
  background-color: #2bc253;
  margin: 0 auto;
  transition: all 0.8s ease;
}

.even-yellow {
  grid-row: 3 / 4;
  grid-column: 7 / 8;
  height: 150%;
  transform: translateY(-35%);
}

.odd-yellow {
  grid-row: 1 / 2;
  grid-column: 7 / 8;
  height: 145%;
}

.even-red {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  height: 150%;
  transform: translateY(-35%);
}

.odd-red {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  height: 145%;
}

.small-red {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.big-red {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  width: 148%;
}

.small-yellow {
  grid-row: 1 / 2;
  grid-column: 5/ 6;
  width: 148%;
  transform: translateX(-34%);
}

.big-yellow {
  grid-row: 1 / 2;
  grid-column: 6 / 7;
}
</style>
