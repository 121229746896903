<template>
  <div
    class="fish"
    :style="`
      background-color:${chip.color.secondary}; 
      border-color:${chip.color.primary};
      color:${chip.color.primary};
      box-shadow: ${chip.color.secondary} 0px 0px 0px 1.5px inset, ${chip.color.primary} 0px 0px 0px 3.5px inset, ${chip.color.secondary} 0px 0px 4px 2px inset, rgba(0, 0, 0, 0.34) -1px -20px 20px 0px inset, rgba(113, 113, 113, 0.36) 0px 0px 5px 3px;
      `"
    @click="$emit('clicked', chip)"
  >
    <span>
      {{ nFormatter(chip.number, 0) }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      nFormatter: 'chips/nFormatter',
    }),
  },
  props: {
    chip: {
      type: Object,
      default: () => ({
        number: '0.25',
        value: 0.25,
        color: {
          primary: '#fff',
          secondary: '#0f0',
        },
        active: true,
        rouletteCurrency: [
          {
            _id: '6377d0e9bc2612755f97534c',
            name: 'Euro',
            short: 'EUR',
            symbol: '€',
            usdExchange: 1.073364,
            createdAt: '2022-11-18T18:37:29.738Z',
            updatedAt: '2024-04-01T16:00:02.986Z',
            __v: 0,
            status: true,
          },
        ],
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.fish {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  margin: 30px;
  border-radius: 50%;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  border-width: 8px;
  border-style: dashed;
  text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
}

.fish span {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bolder;
}
</style>
